import React from 'react';
import './ContactUsPage.css';
import HubSpotFormContactPage from '../Components/HubSpotFormContactPage';
import bannerImage from '../Assets/rectangleKanjiBanner.png';

function ContactUsPage() {
    return (
      <div>
        <div className="kanji-banner">
          <img src={bannerImage} alt="Kanji Banner" className="kanji-banner-image"/>
          <div className="kanji-banner-text">Contact Us</div>
        </div>
        <div>
          <div className="about-page white-contact-section">
            <h1 className="main-headline">What's your preference?</h1>
            <p className="white-section-paragraph">
                There's a couple of ways to get started. You can either send us an email, 
                book an appointment, or send us your contact information and one of our 
                Cyberai consultants will reach out shortly.
            </p>
          </div>
          <div className="contact-options-section">
            <div className="appointment-section">
              <h1>Schedule a Call</h1>
              <button 
                className="homepage-demo-button" 
                onClick={() => window.open('https://meetings-eu1.hubspot.com/william-ochs', '_blank')}
              >
                BOOK APPOINTMENT
              </button>
            </div>
            <div className="email-section">
              <h1>Send an Email</h1>
              <p className="email-section"><a href="mailto:info@kanjisecurity.com?subject=Website%20Inquiry:%20Kanji%20Security%20Services%20Needed">info@kanjisecurity.com</a></p>
            </div>
          </div>
        </div>
        <div className="white-contact-section hubspot-contactus-form-section">
          <h1 className='contact-form-header'>Send Contact Details</h1>
          <HubSpotFormContactPage />
        </div>
      </div>
    );
  }
  
  export default ContactUsPage;