// src/components/HubSpotFormAboutPage.jsx
import React, { useEffect } from 'react';

const HubSpotFormAboutPage = () => {
  useEffect(() => {
    // Load the HubSpot script
    const script = document.createElement('script');
    script.src = "//js-eu1.hsforms.net/forms/embed/v2.js";
    script.async = true;
    document.body.appendChild(script);

    script.onload = () => {
      if (window.hbspt) {
        // Initialize the form
        window.hbspt.forms.create({
          region: "eu1",
          portalId: "145329967",
          formId: "fca51dac-4c33-4bad-bcf1-4c3f3e40d5fd",
          target: '#hubspotForm'
        });
      }
    };

    // Clean up
    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <div className="hubspot-form-container">
      <h2 className="form-heading">Let's Get Started</h2>
      <div id="hubspotForm"></div>
    </div>
  );
};

export default HubSpotFormAboutPage;