import React from 'react';
import './AboutPage.css';
import bannerImage from '../Assets/rectangleKanjiBanner.png';
import bushidoImage from '../Assets/bushidoNoBackground.png';
import penPointingImage from '../Assets/rectanglePenPointing.jpg';
import HubSpotFormAboutPage from '../Components/HubSpotFormAboutPage';

function AboutPage() {
    return (
        <div>
            <div className="kanji-banner">
                <img src={bannerImage} alt="Kanji Banner" className="kanji-banner-image"/>
                <div className="kanji-banner-text">About Kanji</div>
            </div>
            <div className="about-page white-section">
                <h1 className="main-headline">What does Kanji mean?</h1>
                <p>
                    "Kanji" refers to the logographic characters used in the modern Japanese writing system. Originally of Chinese origin, these characters were adopted by Japan over one thousand years ago. 
                    In Japanese, kanji characters are used to represent words or concepts, and they often have multiple readings depending on their context. 
                    Each kanji can stand alone as a word, or combine with other kanji to create more complex words or ideas.
                </p>
            </div>
            <div className="orange-stripe-section">
                <div className="philosophy-content">
                    <div>
                        <h1 className='philosophy-headline'>Our Philosophy</h1>
                        <p className="philosophy-text">
                            The word "Bushido" translates to "the way of the warrior" and embodies a set of values and principles that guided the samurai in their conduct, both in battle and in daily life.
                            Here at Kanji Security, we uphold the same seven core values:
                        </p>
                        <ul className="core-values-list">
                            <li>
                                <span className="left">Courage</span>
                                <span className="center">勇</span>
                                <span className="right">Yū</span>
                            </li>
                            <li>
                                <span className="left">Integrity</span>
                                <span className="center">誠実</span>
                                <span className="right">Seijitsu</span>
                            </li>
                            <li>
                                <span className="left">Benevolence</span>
                                <span className="center">仁</span>
                                <span className="right">Jin</span>
                            </li>
                            <li>
                                <span className="left">Respect</span>
                                <span className="center">礼</span>
                                <span className="right">Rei</span>
                            </li>
                            <li>
                                <span className="left">Honesty</span>
                                <span className="center">誠</span>
                                <span className="right">Makoto</span>
                            </li>
                            <li>
                                <span className="left">Honor</span>
                                <span className="center">名誉</span>
                                <span className="right">Meiyo</span>
                            </li>
                            <li>
                                <span className="left">Loyalty</span>
                                <span className="center">忠義</span>
                                <span className="right">Chūgi</span>
                            </li>
                        </ul>
                        <p className='core-values-text'>
                            Similarly, Kanji Security's Cyberai leaders are experts in their field. Each can work independently, yet when collaborating in a team forms even stronger and more innovative solutions for our clients.
                            Our kanji is inspired by the Bushido, a traditional code of honor and morals developed by the Japanese samurai. 
                        </p>
                    </div>
                    <div className="philosophy-image">
                            <img src={bushidoImage} alt="Bushido" className="bushido-image"/>
                    </div>
                </div>
            </div>
            <div className="about-page white-section">
                <h1 className="main-headline">Global Experts</h1>
                    <p>
                        Our international team exudes our company values in every interaction and has unparalleled experience in the Fortune-50, Fortune-100, and Fortune-500 companies, as well as many small to medium enterprises worldwide. 
                        With clients across North America and Europe, our Cyberai consultants embody the company’s values, bring their expertise, and ensure the highest level of security for our clients and partners, no matter the scale of the organization.
                    </p>
            </div>
            <div className="image-section">
                <img src={penPointingImage} alt="Pen Pointing" className="pen-pointing-image"/>
            </div>
            <div className="about-page white-section">
                <h1 className="main-headline">Our Commitment to You</h1>
                    <p>
                        At Kanji Security, we believe in building lasting partnerships with our stakeholders. We believe that trust is the 
                        foundation of any relationship, and we are committed to acting in accordance with our key values in 
                        everything we do. As our client, you can count on us to protect your business with the highest level of dedication 
                        and professionalism.
                    </p>
            </div>
            <div className="grey-stripe-section about-page">
                <h1>A Message From Our Founder</h1>
                <p className="founder-quote">
                    <em>"In its simplest form, Bushido is alignment with the breath. Harmony within yourself and with the world around 
                    allows our Kanji Security Cyberai to act with integrity, even in the midst of chaos. It is these crucial moments, you'll see our team rise, where 
                    others falter."</em>
                </p>
                <p className="quote-author">
                    - <a href="https://www.linkedin.com/in/williamochs/" target="_blank" rel="noopener noreferrer" className='linkedin-link'>William Ochs</a>, Founder and CEO
                </p>
            </div>
            <HubSpotFormAboutPage />
        </div>
    );
}

export default AboutPage;