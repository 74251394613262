import React, { useState } from 'react';
import './NavBar.css';
import { Link } from 'react-router-dom';
import mainLogo from '../Assets/navigationLogo.png';

function Navbar() {
    const [isMenuOpen, setIsMenuOpen] = useState(false);

    const toggleMenu = () => {
        setIsMenuOpen(!isMenuOpen);
    };

    const closeMenu = () => {
        setIsMenuOpen(false);
    };
  
    return (
        <nav className="navbar">
            <div className="navbar-container">
                <Link to="/" onClick={closeMenu}>
                    <img 
                        src={mainLogo} 
                        alt="Logo" 
                        style={{ width: '150px', height: 'auto' }} 
                        className="navbar-demo-logo" 
                    />
                </Link>
                <div className="navbar-content">
                    <ul className={`navbar-items ${isMenuOpen ? 'open' : ''}`}>
                        <button className="navbar-close" onClick={toggleMenu}>×</button>
                        <li className="navbar-item"><Link to="/" onClick={closeMenu}>HOME</Link></li>
                        <li className="navbar-item"><Link to="/about" onClick={closeMenu}>ABOUT</Link></li>
                        <li className="navbar-item"><Link to="/services" onClick={closeMenu}>SERVICES</Link></li>
                        <li className="navbar-item"><Link to="/contact" onClick={closeMenu}>CONTACT</Link></li>
                    </ul>
                    <button className="navbar-button" onClick={toggleMenu}>
                        &#9776;
                    </button>
                    <button 
                        className="navbar-demo-button" 
                        onClick={() => window.open('https://meetings-eu1.hubspot.com/william-ochs', '_blank')}
                    >
                        BOOK APPOINTMENT
                    </button>
                </div>
            </div>
        </nav>
    );
}

export default Navbar;