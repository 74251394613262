import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import ScrollToTop from '../Components/ScrollToTop';
import NavBar from '../NavBar/NavBar';
import HomePage from '../HomePage/HomePage';
import AboutPage from '../AboutPage/AboutPage';
import ServicesPage from '../ServicesPage/ServicesPage';
import ContactUsPage from '../ContactUsPage/ContactUsPage';
import Footer from '../Footer/Footer';
import './App.css';

function App() {
  return (
    <Router>
      <ScrollToTop />
      <div className="container">
        <NavBar />
        <div className="App">
          <Routes>
            <Route path="/" element={<HomePage />} exact />
            <Route path="/about" element={<AboutPage />} />
            <Route path="/services" element={<ServicesPage />} />
            <Route path="/contact" element={<ContactUsPage />} />
          </Routes>
        </div>
        <Footer />
      </div>
    </Router>
  );
}

export default App;